import './scss/style.scss';
import 'bootstrap';
import { WOW } from 'wowjs';
import { Swiper } from 'swiper/js/swiper.esm.js';
import '../node_modules/select2/dist/js/select2.full';

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

var lang = document.documentElement.lang;

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html");
$('footer').load("footer.html");

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiper = new Swiper('#slider-main', {
        autoplay: {
            delay: 5000,
        },
        loop: true
    });
}

// ver : https://stackoverflow.com/questions/38473207/how-can-i-using-select2-with-webpack
// ver : https://github.com/select2/select2/issues/5372#issuecomment-429705664)
// import 'select2';
// import 'select2/dist/css/select2.css';
// ------------------------------------------------------------------------------------------------------------------------------

const headerSearchField = document.querySelector("#search-text-menu");
const headerMenuFilters = document.querySelector("#menu-filters");

const searchTextMenu = document.querySelector("#search-text-menu");
const searchTextMenuHidden = document.querySelector("#search-text-menu-hidden");
const componentFilters = document.querySelector("#component-filters");
const btnCloseFilters = document.querySelector("#close-filters");

// necesario placeholder select 2
$(document).ready(function () {
    $('.multiple-filter').select2({
        placeholder: "Placeholder ejemplo",
        allowClear: true
    });
    $('#objetivo').select2({
        placeholder: "Objetivo",
        allowClear: true
    });
    $('#competencia').select2({
        placeholder: "Competencia",
        allowClear: true
    });
    $('#valor').select2({
        placeholder: "Valor",
        allowClear: true
    });
    $('.input-destinatraios').select2({
        placeholder: lang === "es" ? "Destinatarios*" : "Recipients*",
        allowClear: true
    });
    $('.input-ods').select2({
        placeholder: lang === "es" ? "Seleccione Objetivos de Desarrollo Sostenible*" : "Select Sustainable Development Goals*",
        allowClear: true
    });
    $('.input-applications').select2({
        width: "100%",
        multiple: true,
        placeholder: lang === "es" ? "Campos de aplicación" : "Field of application",
        allowClear: true
    });
    $('.input-seeker-fields').select2({
        width: "100%",
        multiple: true,
        placeholder: lang === "es" ? "Ámbito o Tecnología" : "Scope or Tech",
        allowClear: true
    });
    $('.input-notifications').select2({
        width: "100%",
        multiple: true,
        placeholder: lang === "es" ? "Recibir notificaciones del ámbito o tecnología" : "Recieve notifications of scope or tech",
        allowClear: true
    });
    
    // tooltip
    $('[data-toggle="tooltip"]').tooltip({
        html: true
    });
    $('[data-toggle="popover"]').popover({
        html: true
    });

    if (searchTextMenu) {
        searchTextMenu.addEventListener("input", (event) => {
            const fieldVal = event.currentTarget.value;
            if (searchTextMenuHidden) {
                searchTextMenuHidden.value = fieldVal
            }
        })
        const currentPage = document.body.getAttribute("data-page");
        if (currentPage != "search") {
            searchTextMenu.addEventListener("mouseover", (event) => {
                if (componentFilters) {
                    componentFilters.classList.add("show");
                    searchTextMenu.disabled = true;
                    document.body.classList.add("of-hidden");
                }
            })
        } else {
            searchTextMenu.disabled = true;
        }
    }
    if (btnCloseFilters) {
        btnCloseFilters.addEventListener("click", (event) => {
            if (componentFilters) {
                componentFilters.classList.remove("show");
                searchTextMenu.disabled = false;
                document.body.classList.remove("of-hidden");
            }
        })
    }
});

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();